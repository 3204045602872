// Import Tabler SCSS files
@import './src/scss/tabler.scss';
@import './src/scss/tabler-vendors.scss';
@import url('https://rsms.me/inter/inter.css');
:root {
  --tblr-font-sans-serif: 'Inter Var', -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
}
.navbar{
  --tblr-navbar-bg: #e03890;
  --tblr-navbar-border-color:var(--tblr-navbar-bg);
}
body {
  font-feature-settings: "cv03", "cv04", "cv11";
}
.page-wrapper{
  padding-bottom: 35px;
}
.menu-footer{
  position:fixed;
  width:100%;
  z-index:15;
  bottom:0;
  left:0;
  font-size:11px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #fff;
  box-shadow: 0px 0px 7px 7px #33333324;
}
.wrap-icon-menu-footer{
  padding:8px 15px;
}
.wrap-icon-menu-footer a{
  color:var(--tblr-gray-600-darken)
}